<template>
  <div class="register-page register-sign-up-page register-beauty">
    <breadCrumbs :current="step + 2" :total="6"></breadCrumbs>
    <div class="container-wide">
      <img src="/static/img/logo-soco.svg" class="logo-soco beauty" alt="register-cover-image" />

      <div v-if="step == 2" class="lh-90">&nbsp;</div>
      <template v-if="step == 3">
        <h2 class="register-tile">MY INTEREST</h2>
        <p class="main-desc text-center">
          <strong>Beritahu kami apa yang Anda sukai.</strong>
        </p>
        <br />
        <p class="main-desc text-center">
          Anda bisa mengisi lain waktu dengan menekan SKIP. <br />Namun kami tetap menyarankan untuk mengisinya.
        </p>
        <router-link to="/register/finish" class="btn skip transparent mb-34">SKIP</router-link>
      </template>
      <template v-if="step == 1">
        <h2 class="register-tile">BEAUTY PROFILE</h2>
        <p class="main-desc text-center">
          <strong
            >Lengkapi beauty profile Anda untuk mendapatkan <br />rekomendasi produk dan konten dari kami.
          </strong>
        </p>
        <br />
        <p class="main-desc text-center">
          Anda bisa mengisi lain waktu dengan menekan SKIP. <br />Namun kami tetap menyarankan untuk mengisinya.
        </p>
        <router-link to="/register/finish" class="btn skip transparent mb-34">SKIP</router-link>
      </template>

      <form method="post" @submit.prevent="submitRegisterBeauty()">
        <div v-if="beautyField" class="beauty-options-wrap">
          <section v-show="step == 1" id="step1" class="beauty-profile-section">
            <div class="items">
              <p class="title">
                <span class="wrapper">
                  <span class="label">WARNA KULIT</span>
                </span>
              </p>
              <div class="container options">
                <div class="row row-no-padding">
                  <div v-for="warnakulit in beautyField['warna-kulit']" :key="warnakulit._id" class="option four-grid">
                    <img :src="warnakulit.image" width="40" height="40" />
                    <p>
                      {{
                        warnakulit.name.toLowerCase().charAt(0).toUpperCase() + warnakulit.name.toLowerCase().slice(1)
                      }}
                    </p>
                    <input
                      v-model="beautyData['warna-kulit']"
                      v-validate="'required'"
                      type="radio"
                      class="radio"
                      name="warnakulit"
                      :value="warnakulit._id"
                      :class="{ haserror: !warnakulitFlags.valid, '': warnakulitFlags.valid }"
                    />
                    <div class="overlay"></div>
                  </div>
                </div>
                <p v-show="errors.has('warnakulit')" class="alert topright">{{ errors.first('warnakulit') }}</p>
              </div>
            </div>

            <div class="items">
              <p class="title">
                <span class="wrapper">
                  <span class="label">UNDERTONE</span>
                </span>
              </p>

              <div class="container options">
                <div class="row row-no-padding">
                  <div v-for="undertone in beautyField.undertone" :key="undertone._id" class="option three-grid">
                    <img
                      height="40"
                      :src="undertone.image"
                      :width="[
                        { '40': undertone.name == 'COOL' },
                        { '40': undertone.name == 'WARM' },
                        { '59': undertone.name == 'NEUTRAL' },
                      ]"
                    />
                    <p>
                      {{ undertone.name.toLowerCase().charAt(0).toUpperCase() + undertone.name.toLowerCase().slice(1) }}
                    </p>
                    <input
                      v-model="beautyData.undertone"
                      v-validate="'required'"
                      type="radio"
                      class="radio"
                      name="undertone"
                      :value="undertone._id"
                      :class="{ haserror: !undertoneFlags.valid, '': undertoneFlags.valid }"
                    />
                    <div class="overlay"></div>
                  </div>
                </div>
                <p v-show="errors.has('undertone')" class="alert topright">{{ errors.first('undertone') }}</p>
              </div>
            </div>

            <div class="items">
              <p class="title">
                <span class="wrapper">
                  <span class="label">
                    JENIS KULIT
                    <span class="info">Anda bisa memilih lebih dari satu pilihan</span>
                  </span>
                </span>
              </p>
              <div class="container options">
                <div class="row row-no-padding">
                  <div v-for="jeniskulit in beautyField['jenis-kulit']" :key="jeniskulit._id" class="option three-grid">
                    <img :src="jeniskulit.image" width="40" height="40" />
                    <p>
                      {{
                        jeniskulit.name.toLowerCase().charAt(0).toUpperCase() + jeniskulit.name.toLowerCase().slice(1)
                      }}
                    </p>
                    <input
                      v-model="beautyData['jenis-kulit']"
                      v-validate="'required'"
                      type="checkbox"
                      class="checkbox"
                      name="jeniskulit"
                      :value="jeniskulit._id"
                      :class="{ haserror: !jeniskulitFlags.valid, '': jeniskulitFlags.valid }"
                    />
                    <div class="overlay"></div>
                  </div>
                </div>
                <p v-show="errors.has('jeniskulit')" class="alert topright">{{ errors.first('jeniskulit') }}</p>
              </div>
            </div>

            <div class="items">
              <p class="title">
                <span class="wrapper">
                  <span class="label">
                    KONDISI KULIT
                    <span class="info">Anda bisa memilih lebih dari satu pilihan</span>
                  </span>
                </span>
              </p>
              <div class="container options">
                <div class="row row-no-padding">
                  <div
                    v-for="kondisikulit in beautyField['kondisi-kulit']"
                    :key="kondisikulit._id"
                    class="option three-grid"
                  >
                    <img :src="kondisikulit.image" width="40" height="40" />
                    <p>
                      {{
                        kondisikulit.name.toLowerCase().charAt(0).toUpperCase() +
                        kondisikulit.name.toLowerCase().slice(1)
                      }}
                    </p>
                    <input
                      v-model="beautyData['kondisi-kulit']"
                      v-validate="'required'"
                      type="checkbox"
                      class="checkbox"
                      name="kondisikulit"
                      :value="kondisikulit._id"
                      :class="{ haserror: !kondisikulitFlags.valid, '': kondisikulitFlags.valid }"
                    />
                    <div class="overlay"></div>
                  </div>
                </div>
                <p v-show="errors.has('kondisikulit')" class="alert topright">{{ errors.first('kondisikulit') }}</p>
              </div>
            </div>

            <div class="items">
              <p class="title">
                <span class="wrapper">
                  <span class="label">BENTUK WAJAH</span>
                </span>
              </p>
              <div class="container options">
                <div class="row row-no-padding">
                  <div
                    v-for="bentukwajah in beautyField['bentuk-wajah']"
                    :key="bentukwajah._id"
                    class="option three-grid"
                  >
                    <img :src="bentukwajah.image" height="40" />
                    <p>
                      {{
                        bentukwajah.name.toLowerCase().charAt(0).toUpperCase() + bentukwajah.name.toLowerCase().slice(1)
                      }}
                    </p>
                    <input
                      v-model="beautyData['bentuk-wajah']"
                      v-validate="'required'"
                      type="radio"
                      class="radio"
                      name="bentukwajah"
                      :value="bentukwajah._id"
                      :class="{ haserror: !bentukwajahFlags.valid, '': bentukwajahFlags.valid }"
                    />
                    <div class="overlay"></div>
                  </div>
                </div>
                <p v-show="errors.has('bentukwajah')" class="alert topright">{{ errors.first('bentukwajah') }}</p>
              </div>
            </div>
          </section>
          <section v-show="step == 2" id="step2" class="beauty-profile-section">
            <div class="items">
              <p class="title">WARNA RAMBUT</p>
              <div class="container options">
                <div class="row row-no-padding">
                  <div
                    v-for="warnarambut in beautyField['warna-rambut']"
                    :key="warnarambut._id"
                    class="option three-grid"
                  >
                    <img :src="warnarambut.image" width="40" height="40" />
                    <p>
                      {{
                        warnarambut.name.toLowerCase().charAt(0).toUpperCase() + warnarambut.name.toLowerCase().slice(1)
                      }}
                    </p>
                    <input
                      v-model="beautyData['warna-rambut']"
                      v-validate="'required'"
                      type="radio"
                      class="radio"
                      name="warnarambut"
                      :value="warnarambut._id"
                      :class="{ haserror: !warnarambutFlags.valid, '': warnarambutFlags.valid }"
                    />
                    <div class="overlay"></div>
                  </div>
                </div>
                <p v-show="errors.has('warnarambut')" class="alert topright">{{ errors.first('warnarambut') }}</p>
              </div>
            </div>

            <div class="items">
              <p class="title">PANJANG RAMBUT</p>
              <div class="container options">
                <div class="row row-no-padding">
                  <div
                    v-for="panjangrambut in beautyField['panjang-rambut']"
                    :key="panjangrambut._id"
                    class="option three-grid"
                  >
                    <img :src="panjangrambut.image" width="28" height="40" />
                    <p>
                      {{
                        panjangrambut.name.toLowerCase().charAt(0).toUpperCase() +
                        panjangrambut.name.toLowerCase().slice(1)
                      }}
                    </p>
                    <input
                      v-model="beautyData['panjang-rambut']"
                      v-validate="'required'"
                      type="radio"
                      class="radio"
                      name="panjangrambut"
                      :value="panjangrambut._id"
                      :class="{ haserror: !panjangrambutFlags.valid, '': panjangrambutFlags.valid }"
                    />
                    <div class="overlay"></div>
                  </div>
                </div>
                <p v-show="errors.has('panjangrambut')" class="alert topright">{{ errors.first('panjangrambut') }}</p>
              </div>
            </div>

            <div class="items">
              <p class="title">JENIS RAMBUT</p>
              <div class="container options">
                <div class="row row-no-padding">
                  <div
                    v-for="jenisrambut in beautyField['jenis-rambut']"
                    :key="jenisrambut._id"
                    class="option three-grid"
                  >
                    <img :src="jenisrambut.image" width="40" height="40" />
                    <p>
                      {{
                        jenisrambut.name.toLowerCase().charAt(0).toUpperCase() + jenisrambut.name.toLowerCase().slice(1)
                      }}
                    </p>
                    <input
                      v-model="beautyData['jenis-rambut']"
                      v-validate="'required'"
                      type="radio"
                      class="radio"
                      name="jenisrambut"
                      :value="jenisrambut._id"
                      :class="{ haserror: !jenisrambutFlags.valid, '': jenisrambutFlags.valid }"
                    />
                    <div class="overlay"></div>
                  </div>
                </div>
                <p v-show="errors.has('jenisrambut')" class="alert topright">{{ errors.first('jenisrambut') }}</p>
              </div>
            </div>

            <div class="items">
              <p class="title">
                <span class="wrapper">
                  <span class="label">
                    KONDISI RAMBUT
                    <span class="info">Anda bisa memilih lebih dari satu pilihan</span>
                  </span>
                </span>
              </p>
              <div class="container options">
                <div class="row row-no-padding">
                  <div
                    v-for="kondisirambut in beautyField['kondisi-rambut']"
                    :key="kondisirambut._id"
                    class="option three-grid"
                  >
                    <img :src="kondisirambut.image" width="40" height="40" />
                    <p>
                      {{
                        kondisirambut.name.toLowerCase().charAt(0).toUpperCase() +
                        kondisirambut.name.toLowerCase().slice(1)
                      }}
                    </p>
                    <input
                      v-model="beautyData['kondisi-rambut']"
                      v-validate="'required'"
                      type="checkbox"
                      class="checkbox"
                      name="kondisirambut"
                      :value="kondisirambut._id"
                      :class="{ haserror: !kondisirambutFlags.valid, '': kondisirambutFlags.valid }"
                    />
                    <div class="overlay"></div>
                  </div>
                </div>
                <p v-show="errors.has('kondisirambut')" class="alert topright">{{ errors.first('kondisirambut') }}</p>
              </div>
            </div>
          </section>
          <section v-show="step > 2" v-if="interestField" id="step3" class="beauty-profile-section">
            <div class="items">
              <p class="title">
                <span class="wrapper">
                  <span class="label">
                    PILIH LOOK FAVORITMU
                    <span class="info">Anda bisa memilih lebih dari satu pilihan</span>
                  </span>
                </span>
              </p>
              <div class="container options">
                <div class="row row-no-padding">
                  <div v-for="look in interestField.look" :key="look._id" class="option three-grid">
                    <img :src="look.image" width="40" height="40" />
                    <p>{{ look.name.toLowerCase().charAt(0).toUpperCase() + look.name.toLowerCase().slice(1) }}</p>
                    <input
                      v-model="beautyData.look"
                      v-validate="'required'"
                      type="checkbox"
                      class="checkbox"
                      name="look"
                      :value="look._id"
                      :class="{ haserror: !lookFlags.valid, '': lookFlags.valid }"
                    />
                    <div class="overlay"></div>
                  </div>
                </div>
                <p v-show="errors.has('look')" class="alert topright">{{ errors.first('look') }}</p>
              </div>
            </div>
            <div class="items">
              <p class="title">
                <span class="wrapper">
                  <span class="label">
                    PILIH TOPIK YANG MENARIK
                    <span class="info">Anda bisa memilih lebih dari satu pilihan</span>
                  </span>
                </span>
              </p>
              <div class="container options">
                <div class="row row-no-padding">
                  <div v-for="topik in interestField.topic" :key="topik._id" class="option three-grid">
                    <img :src="topik.image" width="40" height="40" />
                    <p>{{ topik.name.toLowerCase().charAt(0).toUpperCase() + topik.name.toLowerCase().slice(1) }}</p>
                    <input
                      v-model="beautyData.topic"
                      v-validate="'required'"
                      type="checkbox"
                      class="checkbox"
                      name="topik"
                      :value="topik._id"
                      :class="{ haserror: !topikFlags.valid, '': topikFlags.valid }"
                    />
                    <div class="overlay"></div>
                  </div>
                </div>
              </div>
              <p v-show="errors.has('topik')" class="alert topright">{{ errors.first('topik') }}</p>
            </div>
            <!-- <button type="buttom" class="submit button button-small" @click.prevent="nextSteps">NEXT</button> -->
          </section>
        </div>

        <div class="fixed-bottom">
          <button v-if="showLoader == false" class="btn half-full" type="submit" @click.prevent="prevSteps">
            <i class="arrow_carrot-left_alt"></i>
            BACK
          </button>
          <button v-if="showLoader == false" class="btn half-full submit" type="submit" @click.prevent="nextSteps">
            NEXT
            <i class="arrow_carrot-right_alt"></i>
          </button>
          <!-- <loaderspin v-else/> -->
        </div>
      </form>
    </div>
    <div v-show="step == 4" class="modal-news-letter">
      <!-- <div class="overlay"></div> -->
      <div class="body">
        <h3 class="title">EMAIL NEWSLETTER</h3>
        <div class="info">
          <p>
            <strong>Kami ingin selalu mengabarkan untuk Anda.</strong>
          </p>
          <p class="mb-40">Pilih informasi yang Anda ingin dapatkan :</p>
        </div>

        <section id="step4" class="beauty-profile-section">
          <form
            id="formNewsLetter"
            data-vv-scope="formNewsLetter"
            name="formNewsLetter"
            @submit.prevent="registerNewsletter()"
          >
            <div class="items newsletter-content">
              <label class="check-container">
                Kecantikan
                <input
                  id="newsletter-1"
                  v-model="newsLetterData.email_beauty"
                  type="checkbox"
                  checked
                  name="newsletter"
                  :value="true"
                />
                <!-- v-validate="'required'" -->
                <span class="checkmark"></span>
              </label>
              <label class="check-container">
                Gaya hidup
                <input
                  id="newsletter-3"
                  v-model="newsLetterData.email_lifestyle"
                  type="checkbox"
                  checked
                  name="newsletter"
                  :value="true"
                />
                <span class="checkmark"></span>
              </label>
              <label class="check-container">
                Promo dan produk terbaru
                <input
                  id="newsletter-2"
                  v-model="newsLetterData.email_promo"
                  type="checkbox"
                  checked
                  name="newsletter"
                  :value="true"
                />
                <span class="checkmark"></span>
              </label>
              <label class="check-container">
                Event / kompetisi berhadiah
                <input
                  id="newsletter-4"
                  v-model="newsLetterData.email_event"
                  type="checkbox"
                  checked
                  name="newsletter"
                  :value="true"
                />
                <span class="checkmark"></span>
              </label>
              <p v-show="errors.has('newsletter')" class="alert">{{ errors.first('newsletter') }}</p>
            </div>
            <button type="submit" class="btn btn-submit-newsletter">SIMPAN</button>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumbs from './bread-crumbs';
import { mapFields } from 'vee-validate';

export default {
  name: 'regBeauty',
  components: {
    breadCrumbs,
  },
  data() {
    return {
      step: 1,
      showLoader: false,
      interestField: null,
      beautyField: null,
      beautyData: {
        'warna-kulit': '',
        undertone: '',
        'jenis-kulit': [],
        'kondisi-kulit': [],
        'bentuk-wajah': '',
        'warna-rambut': '',
        'panjang-rambut': '',
        'jenis-rambut': '',
        'kondisi-rambut': [],
        look: [],
        topic: [],
      },
      newsLetterData: {
        email_beauty: true,
        email_event: true,
        email_lifestyle: true,
        email_promo: true,
        _id: null,
      },
    };
  },
  mounted() {
    this.getBeautyField();
    this.getInterestField();
  },
  methods: {
    registerNewsletter() {
      this.$validator.validateAll('formNewsLetter').then((result) => {
        if (result) {
          // alert("Newsletter form is valid!");
          this.newsLetterData._id = this.$store.state.auth.userProfile._id;
          console.log(this.newsLetterData);
          this.$MS_SOCO_PUBLIC_API_URL.post('user/me/preferences', this.newsLetterData).then((res) => {
            if (res.data.success) {
              this.$router.push('/register/finish');
              // const data = res.data.data;
              // console.log(data);
            }
          });
        }
      });
    },
    registerBeautyProfile() {
      const paramsbeauty = [];
      const _this = this;
      for (const key in this.beautyField) {
        const subtags = [];
        (_this.beautyField[key] || []).map(function (row) {
          if (_this.beautyData[key].indexOf(row._id) !== -1) {
            subtags.push(row);
          }
        });
        paramsbeauty.push({
          type: 'beauty-profile',
          name: key,
          code: key,
          subtags: subtags,
        });
      }
      // console.log(paramsbeauty);
      this.$MS_SOCO_PUBLIC_API_URL
        .post('user/me/tags', {
          beauty: paramsbeauty,
        })
        .then((res) => {
          if (res.data.success) {
            this.step = 3;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    registerBeautyInterest() {
      const paramsinterest = [];
      const _this = this;
      for (const key in this.interestField) {
        const subtags = [];
        (_this.interestField[key] || []).map((row) => {
          if (_this.beautyData[key].indexOf(row._id) !== -1) {
            subtags.push(row);
          }
        });
        paramsinterest.push({
          type: 'beauty-interest',
          name: key,
          code: key,
          subtags: subtags,
        });
      }

      this.$MS_SOCO_PUBLIC_API_URL
        .post('user/me/tags', {
          interest: paramsinterest,
          newRegister: true,
        })
        .then((res) => {
          if (res.data.success) {
            this.step = 4;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    nextSteps() {
      if (this.step === 1) {
        this.$validator.validate('warnakulit').then((result1) => {
          this.$validator.validate('kondisikulit').then((result2) => {
            this.$validator.validate('bentukwajah').then((result3) => {
              this.$validator.validate('undertone').then((result4) => {
                this.$validator.validate('jeniskulit').then((result5) => {
                  if (result1 && result2 && result3 && result4 && result5) {
                    this.step = 2;
                    window.scrollTo(0, 0);
                  }
                });
              });
            });
          });
        });
      }
      if (this.step === 2) {
        this.$validator.validate('warnarambut').then((result6) => {
          this.$validator.validate('panjangrambut').then((result7) => {
            this.$validator.validate('jenisrambut').then((result8) => {
              this.$validator.validate('kondisirambut').then((result9) => {
                if (result6 && result7 && result8 && result9) {
                  window.scrollTo(0, 0);
                  this.registerBeautyProfile();
                }
              });
            });
          });
        });
      }
      if (this.step === 3) {
        this.$validator.validate('look').then((result1) => {
          this.$validator.validate('topik').then((result2) => {
            if (result1 && result2) {
              this.registerBeautyInterest();
            }
          });
        });
      }
      document.getElementsByClassName('haserror')[0].parentNode.parentNode.parentNode.parentNode.scrollIntoView();
    },
    prevSteps() {
      if (this.step < 2) {
        this.$router.push('/register/profile');
      } else {
        this.step -= 1;
        window.scrollTo(0, 0);
      }
      // alert(this.step);
    },
    getInterestField() {
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`tags?type=beauty-interest`)
        .then((res) => {
          const interest = res.data.data;
          this.interestField = {};
          interest.map((row) => {
            if (row.code == 'favourite-look') {
              this.interestField.look = row.subtags;
            } else if (row.code == 'favourite-topics') {
              this.interestField.topic = row.subtags;
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getBeautyField() {
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`tags?type=beauty-profile`)
        .then((res) => {
          const beauty = res.data.data;
          this.beautyField = {};
          beauty.map((row) => {
            this.beautyField[row.code] = row.subtags;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    submitRegisterBeauty() {
      this.$MS_SOCO_PUBLIC_API_URL
        .post(`/auth/register/update`, this.registerData)
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.data);
            this.$toasted.global.show('Berhasil mendaftarkan beauty profile');
          } else if (!res.data.success && res.data.message) {
            this.$toasted.global.error(this.hummanizeMessage(res.data.message));
          } else {
            this.$toasted.global.error('Gagal mendaftarkan beauty profile anda');
          }
        })
        .catch();
      console.log('wkwkw ee ee');
    },
  },
  computed: {
    ...mapFields({
      warnakulitFlags: 'warnakulit',
      undertoneFlags: 'undertone',
      jeniskulitFlags: 'jeniskulit',
      kondisikulitFlags: 'kondisikulit',
      bentukwajahFlags: 'bentukwajah',
      warnarambutFlags: 'warnarambut',
      panjangrambutFlags: 'panjangrambut',
      jenisrambutFlags: 'jenisrambut',
      kondisirambutFlags: 'kondisirambut',
      lookFlags: 'lookrambut',
      topikFlags: 'topikrambut',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
